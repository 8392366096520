import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSubtotal,
  removeAllFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";

import TagManager from "react-gtm-module";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const { items: shipping } = useSelector((state) => state.shippingCharge);
  console.log(shipping);
  // const [orderItem, setOrderItem] = useState(data);
  const [response, setResponse] = useState(0);
  const [resOK, setResok] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: null,
    shipping_type: "Cash On Delivery",
  });
  const [couponCode, setCouponCode] = useState("");

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };
  const applyCoupon = async () => {
    setCouponCode("");
    try {
      await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: couponCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(data);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  let grand_total = response.discount
    ? cartTotalAmount + +formData.shipping_cost + -response.discount
    : cartTotalAmount + +formData.shipping_cost + 0;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    TagManager.dataLayer({
      dataLayer: {
        event: "purchase", // Event name
        buttonName: "purchase",
        items: data,
      },
    });

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: data,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) {
          setResok(true);
          throw new Error("Something Went Error");
        }
        if (res.ok) {
          setResok(false);
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        setResok(true);
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        // item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };

  return (
    <>
      {data?.length >= 1 ? (
        <div className="checkout-page px-2 mt-10 mb-10 container min-h-screen mx-auto grid grid-cols-1 md:grid-cols-2 ">
          <form onSubmit={handleFormSubmit}>
            <div className="billing-address flex flex-col order-2 md:order-1">
              <h2 className="text-2xl mt-5 md:mt-0 mb-5">Billing Address</h2>
              <div className="form-control flex flex-col gap-2  mb-3">
                <label className="mb-2">Full Name</label>
                <input
                  required
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  value={formData.name}
                  className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                  placeholder="Enter Your First Name"
                />
              </div>
              <div className="form-control flex flex-col mb-3">
                <label className="mb-2">Phone Number</label>
                <input
                  required
                  type="tel"
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  value={formData.phone}
                  className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                  placeholder="Enter Your Number"
                />
              </div>

              <div className="form-control flex flex-col  mb-3 ">
                <label className="mb-2">Address</label>
                <textarea
                  required
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  value={formData.address}
                  className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                  placeholder="Enter Your Address"
                />
              </div>

              <div className="form-control flex flex-col gap-2  mb-3 bg-orange-600">
                <select
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, shipping_cost: e.target.value })
                  }
                  className="px-3 py-2  rounded-md outline-none border bg-[#F4A996] focus:border-[#96680e] duration-300"
                >
                  <option value="">Select Area</option>
                  {shipping?.map((data) => (
                    <option className="" key={data?.id} value={data?.amount}>
                      {data?.name}({data?.amount})
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <button
                  type="submit"
                  disabled={resOK}
                  className={` ${
                    resOK ? " bg-gray-600 text-white " : " bg-[#0ea5e9]"
                  }   px-5 py-3 rounded-md w-full`}
                >
                  {resOK ? "Ordering..." : "Place Order"}
                </button>
              </div>
            </div>
          </form>

          <div className="order-details md:ml-40 ml-0 md:order-2 order-1">
            <h2 className="text-2xl">Order Details</h2>

            <div className="h-auto overflow-y-scroll order-details-container">
              {data?.map((product) => (
                <div
                  key={product.id}
                  className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
                >
                  <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                      className="w-full object-cover "
                      alt=""
                    />
                  </div>
                  <div className="cart-description w-full overflow-hidden ">
                    <div className="flex flex-col">
                      <p className="truncate">
                        {product?.name.substring(0, 60)}...
                      </p>
                      <div className="cart-price">
                        {product.after_discount > 0 ? (
                          <>
                            ৳{product?.after_discount * product?.cartQuantity}
                          </>
                        ) : (
                          <>৳{product?.unit_price * product?.cartQuantity}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
              <div>
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    checked
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        shipping_type: e.target.value,
                      })
                    }
                    name="shipping_type"
                    value={"Cash On Delivery"}
                    className="peer sr-only"
                  />
                  <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                    <img
                      src="./cash_on_delivery.jpg"
                      alt=""
                      className="w-full h-12 "
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="payment-summary flex flex-col gap-3 mt-5 ">
              <div className="sub-total flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold">
                  Sub Total
                </h4>
                <span className="text-slate-700 ">৳{cartTotalAmount}</span>
              </div>
              <div className="shipping flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold ">
                  Shipping
                </h4>
                <span className="text-slate-700 ">
                  ৳{+formData.shipping_cost}
                </span>
              </div>
              <div className="shipping flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold ">
                  Coupon Discount
                </h4>
                <span className="text-slate-700 ">
                  ৳{response.discount ? +response.discount : 0}
                </span>
              </div>
              <div className="sub-total flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold">Total</h4>
                <span className="text-slate-700 font-semibold ">
                  ৳{grand_total}
                </span>
              </div>
              <div>
                <div className="flex   gap-3">
                  <input
                    type="text"
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                    className="px-3 py-2 xl:w-72  w-60  rounded-md outline-none border"
                  />
                  <button
                    onClick={applyCoupon}
                    className="bg-[#0ea5e9] px-3  text-sm rounded-md"
                  >
                    Apply Coupon
                  </button>
                </div>
                {response.status === 200 && (
                  <p className="text-green-500 text-xs mt-2">
                    {response.massage}
                  </p>
                )}
                {response.status === 404 && (
                  <p className="text-rose-500 text-xs mt-2">
                    {response.massage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1>No product for checkout plse add to cart</h1>
        </div>
      )}
    </>
  );
};

export default Checkout;
