import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  // ListItemSuffix,
  // Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { BiLeftArrowAlt } from "react-icons/bi";

const SideBar = ({ handleClose }) => {
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const [open, setOpen] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);
  };
  const { items: categories } = useSelector((state) => state.category);
  const { items: subcategories } = useSelector((state) => state.subCategor);
  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };
  return (
    <div>
      <Card className=" filterSideNav-wrapper rounded-none   h-100vh z-[999999] w-full max-w-[18rem]  overflow-y-scroll fixed  left-0 bottom-0 top-0 p-2 shadow-xl shadow-blue-gray-900/5">
        <div className="">
          <Typography variant="h5" color="blue-gray">
            <BiLeftArrowAlt onClick={handleClose} className="text-[1.5rem]" />
          </Typography>
        </div>
        <List>
          <div className="text-2xl text-[#03A9F4] mb-4">Category</div>
          {categories?.map((category, index) => (
            <Accordion
              key={index}
              open={open === index}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === index ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === index}>
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className="border-b-0 p-3"
                >
                  {/* <ListItemPrefix>
                <PresentationChartBarIcon className="h-5 w-5" />
              </ListItemPrefix> */}
                  <Typography
                    color="blue-gray"
                    className="mr-auto font-normal hover:text-[#F9921F]"
                  >
                    {category.name}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  {getSubcategoriesByCategoryId(category.id).map(
                    (subcategory, subIndex) => (
                      <Link
                        key={subIndex}
                        to={`/subcategory/${subcategory.id}`}
                      >
                        <ListItem onClick={handleClose}>
                          <ListItemPrefix>
                            <ChevronRightIcon
                              strokeWidth={3}
                              className="h-3 w-5"
                            />
                          </ListItemPrefix>

                          {subcategory.name}
                        </ListItem>
                      </Link>
                    )
                  )}
                </List>
              </AccordionBody>
            </Accordion>
          ))}
        </List>
      </Card>
    </div>
  );
};

export default SideBar;
